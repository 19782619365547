<template>
    <div>
        <div class="infos_ativo_sonda">
            <div>
                <img :src="ativo.nm_caminho_imagem" alt="" height="100px" width="80%"/>
            </div>
            <div>
                <span class="span_p_sonda">Descrição:          </span>{{ ativo.nm_descricao }}<br>
                <span class="span_p_sonda">Número de série:    </span>{{ ativo.nm_serial_number }}<br>
                <span class="span_p_sonda">Fábricante:         </span>{{ ativo.nm_fabricante }}<br>
                <span class="span_p_sonda">Quantidade de Us:   </span>{{ ativo.qt_us }}<br>
                <span class="span_p_sonda">Cliente:            </span>{{ ativo.nm_cliente }} <br>
                <span class="span_p_sonda">Proprietário:       </span>{{ ativo.nm_proprietario }} <br>
                <span class="span_p_sonda">Data de instalação: </span>{{ ativo.dt_instalacao }}
            </div>
        </div>
    </div>
</template>
<script>
export default{
    props:{
        ativo: Object,
        ativos: Array
    },
}
</script>
<style>
.infos_ativo_sonda{
  display: flex;
  align-items: center;
}
.span_p_sonda{
  margin-right: 10px;
  color: rgb(0, 0, 0);
  font-weight: bold;
}
</style>