<template>
  <div>
    <div class="container">
      <div class="card_home">
        <div class="container-card">
          <p class="card-title-home">Atualizações!</p>
          <p class="card-description">1-Agora você pode alterar as cores do Sistema! Acesse o portal do usuário e escolha a que mais combina com você!</p>
          <p class="card-description">2-Para acessar o suporte, basta clicar em cima da sua foto de perfil e selecionar a opção "Suporte"</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.container {
  margin: 30px;
}
.card_home {
  width: 100%;
  border: 0;
  margin-inline: auto;
}
.container-card {
  border: 2px solid var(--main_color);
  background-color: var(--main_color);
  border-radius: 20px;
  padding: 20px;
}
.card-title-home {
  font-weight: 600;
  color: rgb(255, 255, 255);
  letter-spacing: -0.02em;
  line-height: 40px;
  font-style: normal;
  font-size: 28px;
}
.card-description {
  font-weight: 600;
  color: hsl(0, 0%, 100%);
  font-size: 16px
}
</style>